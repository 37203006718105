import React from 'react';
import {
  ComponentPreview,
  DontDo,
  DontDoItem,
  List,
  Link,
  PageHero,
  PageNavigation,
  PageNavigationLink,
  PageWithSubNav,
  Paragraph,
  PlatformTable,
  PlatformTableRow,
  Section,
  SectionSubhead,
} from 'docComponents';
import { DataTable } from 'hudl-uniform-ui-components';
import dataTablePreviewData from '../../../data/previews/data-table.yml';
import pageHeroData from '../../../data/pages/components.yml';
import styles from './styles/dataTable.module.scss';

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      columnSorting: [false, false, false, false, false],
      functionSelection: 'fixedheader',
      elementSelection: 'elementheader',
    };
  }

  handleElementChange(value) {
    this.setState({
      elementSelection: value,
    });
  }

  handleFunctionChange(value) {
    this.setState({
      functionSelection: value,
      columnSorting:
        value === 'sorting'
          ? [true, false, true, false, false]
          : [false, false, false, false, false],
    });
  }

  render() {
    return (
      <PageWithSubNav
        pageType="design"
        title="Data Table"
        designCode
        subnav="components">
        <PageHero heroData={pageHeroData} tierTwo="Data Table" />
        <Paragraph>
          This goes beyond box scores and stats reports to any instance where
          the numbers could be accessed, analyzed and acted upon—including
          rosters, schedules and payment history.
        </Paragraph>
        <PageNavigation>
          <PageNavigationLink>Elements</PageNavigationLink>
          <PageNavigationLink>Size</PageNavigationLink>
          <PageNavigationLink>Style</PageNavigationLink>
          <PageNavigationLink>Functions</PageNavigationLink>
          <PageNavigationLink>Mobile</PageNavigationLink>
          <PageNavigationLink>Usage</PageNavigationLink>
          <PageNavigationLink>Platform</PageNavigationLink>
        </PageNavigation>
        <Section title="Elements">
          <Paragraph>
            Every data table is comprised of one or more headers, rows, columns,
            cells and the occasional footer.
          </Paragraph>
          <ComponentPreview
            name="DataTableElements"
            layout="default"
            previewData={dataTablePreviewData.elements}
            onChange={this.handleElementChange.bind(this)}>
            <div
              className={
                styles.dataTableElementsWrapper +
                ' ' +
                this.state.elementSelection
              }>
              <DataTable
                columnContentTypes={[
                  'text',
                  'numeric',
                  'numeric',
                  'numeric',
                  'numeric',
                  'numeric',
                ]}
                columnShowSortArrows={[false, false, false, false, false]}
                columnHeaders={['', 'G', 'MP', 'FG', 'FGA', 'FG%']}
                rows={[
                  {
                    id: '1',
                    data: ['Team', '75', '17985', '2944', '6480', '.454'],
                  },
                  {
                    id: '2',
                    data: ['Team/G', '', '243.0', '39.8', '87.6', '.454'],
                  },
                  { id: '3', data: ['Lg Rank', '', '4', '20', '20', '18'] },
                  {
                    id: '3',
                    data: ['Year/Year', '', '0.4%', '2.9%', '-1.4%', '+.019'],
                  },
                ]}
              />
            </div>
          </ComponentPreview>
        </Section>
        <Section title="Size">
          <Paragraph>
            Cell size is informed by content size. Consider view density and the
            amount of content when choosing between the four available options.
          </Paragraph>
          <ComponentPreview
            name="DataTableSize"
            layout="default"
            previewData={dataTablePreviewData.sizes}>
            <DataTable
              style="freeform"
              columnContentTypes={['text', 'numeric', 'text', 'text', 'text']}
              columnShowSortArrows={[true, true, false, true, true]}
              columnHeaders={['Name', 'Jersey', 'Position', 'Height', 'Weight']}
              rows={[
                {
                  id: '1',
                  data: ['Rawle Alkins', '20', 'SG', '6\'5"', '225 lbs'],
                },
                {
                  id: '2',
                  data: ['Chandler Hutchinson', '15', 'SF', '6\'7"', '197 lbs'],
                },
                {
                  id: '3',
                  data: ['Zach LaVine', '8', 'PG', '6\'7"', '200 lbs'],
                },
              ]}
            />
          </ComponentPreview>
        </Section>
        <Section title="Style">
          <Paragraph>
            In some cases, like a very dense and/or long table, use styling to
            break up the content and make things more digestible.
          </Paragraph>
          <ComponentPreview
            name="DataTableStyle"
            layout="default"
            previewData={dataTablePreviewData.styles}>
            <DataTable
              columnContentTypes={[
                'text',
                'numeric',
                'numeric',
                'numeric',
                'numeric',
                'numeric',
              ]}
              columnShowSortArrows={[false, false, false, false, false]}
              columnHeaders={['', 'G', 'MP', 'FG', 'FGA', 'FG%']}
              rows={[
                {
                  id: '1',
                  data: ['Team', '75', '17985', '2944', '6480', '.454'],
                },
                {
                  id: '2',
                  data: ['Team/G', '', '243.0', '39.8', '87.6', '.454'],
                },
                { id: '3', data: ['Lg Rank', '', '4', '20', '20', '18'] },
                {
                  id: '3',
                  data: ['Year/Year', '', '0.4%', '2.9%', '-1.4%', '+.019'],
                },
              ]}
            />
          </ComponentPreview>
        </Section>
        <Section title="Functions">
          <Paragraph>
            While the data itself is static, the table can be manipulated to
            make consumption a little easier.
          </Paragraph>
          <ComponentPreview
            name="DataTableFunctions"
            layout="default"
            previewData={dataTablePreviewData.functions}
            onChange={this.handleFunctionChange.bind(this)}>
            <div
              className={
                styles.dataTableFunctionsWrapper +
                ' ' +
                this.state.functionSelection
              }>
              <DataTable
                style="default"
                showTableBorder={this.state.functionSelection === 'fixedheader'}
                freezeFirst={
                  this.state.functionSelection === 'fixedcolumn'
                    ? 'column'
                    : 'row'
                }
                columnContentTypes={['text', 'numeric', 'text', 'text', 'text']}
                columnShowSortArrows={this.state.columnSorting}
                columnHeaders={[
                  'Name',
                  'Jersey',
                  'Position',
                  'Height',
                  'Weight',
                ]}
                rows={[
                  {
                    id: '1',
                    data: ['Rawle Alkins', '20', 'SG', '6\'5"', '225 lbs'],
                  },
                  {
                    id: '2',
                    data: [
                      'Chandler Hutchinson',
                      '15',
                      'SF',
                      '6\'7"',
                      '197 lbs',
                    ],
                  },
                  {
                    id: '3',
                    data: ['Zach LaVine', '8', 'PG', '6\'7"', '200 lbs'],
                  },
                ]}
              />
            </div>
          </ComponentPreview>
        </Section>
        <Section title="Mobile">
          <Paragraph>
            Uniform data tables should only be used on desktops. Instead, when
            designing for mobile use the{' '}
            <Link href="https://developer.apple.com/design/human-interface-guidelines/ios/views/tables/">
              table view
            </Link>{' '}
            to accurately display information if it makes sense. Data tables
            will be displayed in single-column views, so in cases when it is
            important to display more than one column of information at a time,
            consider designing a separate view with the information in a
            different presentation, like a list.
          </Paragraph>
        </Section>
        <Section title="Usage">
          <SectionSubhead>Alignment</SectionSubhead>
          <Paragraph>
            <strong>Data table content should never be centered.</strong> For
            all names, titles and other text, align left. Numbers stay on the
            right.
          </Paragraph>
          <Paragraph>
            Centered content becomes more difficult to scan as the table becomes
            more dense. Give the user a line, however (in)visible, to track
            alongside.
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="center the content in every cell, regardless of table size."
              img="datatable-center-dont"
            />
            <DontDoItem
              type="do"
              text="align left or right depending on content type."
              img="datatable-center-do"
            />
          </DontDo>
          <SectionSubhead>Links</SectionSubhead>
          <Paragraph>
            Numbers and text may be linked to connect content within Hudl, like
            a video, playlist or highlight.{' '}
            <Link href="/components/link/design#Type">Use any type</Link>{' '}
            <em>except button</em>.
          </Paragraph>
          <Paragraph>
            Just don’t go too crazy with linked content. Wherever you take the
            user should tie directly to the table they’re studying. Don’t
            totally change context with the option to check a tutorial or
            contact support.
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="link to several external sources and interrupt the experience."
              img="datatable-link-dont"
            />
            <DontDoItem
              type="do"
              text="link to any Hudl content feeding that specific table."
              img="datatable-link-do"
            />
          </DontDo>
          <SectionSubhead>Text Formatting</SectionSubhead>
          <Paragraph>
            Bold text is reserved for headers, nothing in the cells. Aside from
            that, you shouldn’t have to format the text at all. Too many
            different styles and the whole table becomes illegible.
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="mix formats in an effort to call out key information."
              img="datatable-format-dont"
            />
            <DontDoItem
              type="do"
              text="feature data that exists well on its own."
              img="datatable-format-do"
            />
          </DontDo>
          <SectionSubhead>Multiple Lines</SectionSubhead>
          <Paragraph>
            Wrapping may happen, but never force a line break. Stick to the
            microcopy guidelines below and you should be safe with a single
            line.
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="use line breaks to put tons of content in a single cell."
              img="datatable-multipleline-dont"
            />
            <DontDoItem
              type="do"
              text="keep things brief to avoid natural breaks."
              img="datatable-multipleline-do"
            />
          </DontDo>
          <SectionSubhead>Selection</SectionSubhead>
          <Paragraph>
            Should your table include selectable content,{' '}
            <Link href="/components/select-mark">the select mark</Link> will
            indicate the user may take action on multiple items.
          </Paragraph>
          <Paragraph>
            Selectable content is the only time a hover state should be applied
            to the data table.
          </Paragraph>
        </Section>
        <Section title="Microcopy">
          <SectionSubhead>Headers</SectionSubhead>
          <Paragraph>
            Like other{' '}
            <Link href="/words/content-elements/labels-non-label-text">
              UI labels
            </Link>
            , table headers should adhere to title case, no complete sentences
            and definitely no punctuation.
          </Paragraph>
          <Paragraph>
            Abbreviations are encouraged in the case of a tight squeeze—again,
            no periods. Just stick to the first three letters of the word
            itself. If the full meaning is lost, use the three letters that
            makes things most clear.
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="get too detailed with what’s in the table."
              img="datatable-headers-dont"
            />
            <DontDoItem
              type="do"
              text="keep things tight and tidy."
              img="datatable-headers-do"
            />
          </DontDo>
          <SectionSubhead>Cell Content</SectionSubhead>
          <Paragraph>
            Each cell should only contain one piece of data. Default to spelling
            things out, but if space becomes an issue, feel free to abbreviate.
          </Paragraph>
          <Paragraph>
            Numbers should always be <strong>cardinal</strong>, no 1st, 2nd,
            etc. Use whole numbers whenever possible, and be sure to include %
            anytime it is a true percentage.
          </Paragraph>
          <Paragraph>
            Display all phone numbers with hyphens to make the full string more
            legible.
          </Paragraph>
          <Paragraph>
            <strong>
              Complete sentences should never appear as cell data.
            </strong>
          </Paragraph>
          <SectionSubhead>Null States</SectionSubhead>
          <Paragraph>
            If a null state appears because the user didn’t complete that
            information, leave the entire cell blank. It’s the clearest way to
            indicate nothing has been added.
          </Paragraph>
          <Paragraph>
            Should a null state be the result of an incomplete
            calculation—numbers only—use a double hyphen. If the calculation
            would be a percentage, include that symbol, as well.
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="create a null state that could be confused for content."
              img="datatable-nullstate-dont"
            />
            <DontDoItem
              type="do"
              text="provide appropriate clues for what content belongs."
              img="datatable-nullstate-do"
            />
          </DontDo>
          <Paragraph>
            If the cell is simply not applicable to that user or their current
            workflow, opt for “n/a”.
          </Paragraph>
          <Paragraph>
            <strong>Only use the number 0 for that actual sum.</strong>
          </Paragraph>
        </Section>
        <Section title="Platform">
          <PlatformTable footerMessage="No guidelines exist for Windows (yet).">
            <PlatformTableRow platform="web" />
            <PlatformTableRow platform="apple">
              <List>
                <li>
                  Follow{' '}
                  <Link href="https://developer.apple.com/design/human-interface-guidelines/ios/views/alerts/">
                    HIG
                  </Link>
                  .
                </li>
              </List>
            </PlatformTableRow>
            <PlatformTableRow platform="android">
              <List>
                <li>
                  Follow{' '}
                  <Link href="https://material.io/design/components/dialogs.html">
                    Material Guidelines
                  </Link>
                  .
                </li>
              </List>
            </PlatformTableRow>
          </PlatformTable>
        </Section>
      </PageWithSubNav>
    );
  }
}
